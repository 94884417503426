import { template as template_253e0421b3bc4c4b8751e3c6922f0493 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_253e0421b3bc4c4b8751e3c6922f0493(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
