import { template as template_866443f915b44f4999236c14cefef4f0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_866443f915b44f4999236c14cefef4f0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
