import { template as template_645a11265a6349f7be87d2c9acc95599 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_645a11265a6349f7be87d2c9acc95599(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
