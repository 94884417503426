import { template as template_e08dc3152e56406c8c75974c7e84e3f1 } from "@ember/template-compiler";
const WelcomeHeader = template_e08dc3152e56406c8c75974c7e84e3f1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
